import React from 'react';
import { Helmet } from 'react-helmet';

interface HeadProps {
  title?: string;
}

const Head: React.FC<HeadProps> = ({ title = 'Jamestown' }) => (
  <Helmet>
    <title>{title}</title>
    <meta name="description" content="Jamestown" />
    <meta name="cryptomus" content="b4709cbd" />
  </Helmet>
);

export default Head;
